import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import styles from "./styles.module.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYmltb2RlbiIsImEiOiJjbGh4Y2Rvbm8wYnMxM2ZwaDIwM3Aya29pIn0.7UXMfE6vq_LCECo6Q-eu3w";

const EquipmentMap = ({ equipment, initMapValues }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(initMapValues[0]);
  const [lat, setLat] = useState(initMapValues[1]);
  const [zoom, setZoom] = useState(initMapValues[2]);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: [lng, lat],
      zoom: zoom,
    });

    const geojson = {
      type: "FeatureCollection",
      features: equipment?.map((item) => {
        if (item?.geoData) {
          return {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [item?.geoData?.ing, item?.geoData?.lat],
            },
            status:
              new Date() - new Date(item?.updatedAt) < 5 * 60 * 1000
                ? "Активно"
                : "Неактивно",
            properties: {
              title: item?.name,
              description: `Статус: ${
                new Date() - new Date(item?.updatedAt) < 5 * 60 * 1000
                  ? "Активно"
                  : "Неактивно"
              }`,
            },
          };
        }
        return;
      }),
    };

    geojson.features.map((feature) =>
      new mapboxgl.Marker({
        color: feature.status === "Активно" ? "green" : "red",
      })
        .setLngLat(feature.geometry.coordinates)
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }) // add popups
            .setHTML(
              "<h3>" +
                feature?.properties?.title +
                "</h3><p>" +
                feature?.properties?.description +
                "</p>"
            )
        )
        .addTo(map.current)
    );
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
  });

  return (
    <div
      ref={mapContainer}
      style={{ width: "100%", height: "100%" }}
      className="map-container"
    />
  );
};

export default EquipmentMap;
