import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  loadTasksRequest: ["payload"],
  loadTasksSuccess: ["tasks"],
  loadTasksFailure: ["error"],

  loadArchiveTasksRequest: ["payload"],
  loadArchiveTasksSuccess: ["tasks"],
  loadArchiveTasksFailure: ["error"],

  editTaskRequest: ["payload"],
  editTaskSuccess: ["task"],
  editTaskFailure: ["error"],

  addTaskRequest: ["payload"],
  addTaskSuccess: ["task"],
  addTaskFailure: ["error"],

  deleteTaskRequest: ["payload"],
  deleteTaskSuccess: ["id"],
  deleteTaskFailure: ["error"],

  downloadTasksRequest: ["payload"],
  downloadTasksSuccess: [""],
  downloadTasksFailure: ["error"],

  archiveTasksRequest: ["payload"],
  archiveTasksSuccess: ["id"],
  archiveTasksFailure: ["error"],

  activateTasksRequest: ["payload"],
  activateTasksSuccess: ["id"],
  activateTasksFailure: ["error"],

  uploadTasksRequest: ["payload"],
  uploadTasksSuccess: [""],
  uploadTasksFailure: ["error"],
});

const tasksActions = {
  Types,
  Creators,
};

export default tasksActions;
