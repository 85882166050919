import React from "react";
import NewPassword from "components/NewPassword";

export const NewPasswordPage = (props) => {
  return (
    <>
      <NewPassword {...props} />
    </>
  );
};
