import Paper from "@material-ui/core/Paper";
import MaterialTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import Button from "components/shared/Button";
import Input from "components/shared/Input";
import ModalWindow from "components/shared/ModalWindow";
import Select from "components/shared/Select";
import { Table } from "components/shared/Table";
import { Formik } from "formik";
import React, { useState } from "react";
import styles from "../styles.module.css";

export const TeamsTable = ({
  addTeam,
  deleteTeam,
  editTeam,
  teams,
  executors,
  masters,
  isRequesting,
  userRole,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const formattedMasters = masters?.map((item) => {
    return {
      value: item.masterId,
      label: `${item.surname} ${item.name}`,
    };
  });

  const formattedExecutors = executors?.map((item) => {
    return {
      value: item.executorId,
      label: `${item.surname} ${item.name}`,
    };
  });

  const initialValues = {
    name: modalData?.name ?? "",
    stamp: modalData?.stamp ?? "",
    masterId: modalData?.master?.masterId ?? "",
    executorIds: modalData?.executors?.map((item) => item.executorId) ?? [""],
    id: modalData?.id ?? "",
    teamId: modalData?.teamId ?? "",
  };

  const requiredKeys = ["name", "stamp", "masterId", "executorIds"];

  const columns = [
    { title: "Наименование", field: "name" },
    { title: "Клеймо", field: "stamp" },
    {
      title: "Мастер",
      field: "masterId",
      render: (rowData) => {
        const master = masters.find(
          (item) => item?.masterId === rowData?.master?.masterId
        );
        return (
          <p>
            {master?.surname} {master?.name} {master?.patronymic}
          </p>
        );
      },
    },
  ];

  const renderRowChildren = (rowData) => {
    return (
      !!rowData?.executors?.length &&
      executors && (
        <TableContainer component={Paper}>
          <MaterialTable aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    borderBottom: 0,
                  }}
                  align="center"
                >
                  ФИО
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: 0,
                  }}
                  align="center"
                >
                  Должность
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: 0,
                  }}
                  align="center"
                >
                  Клеймо
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: 0,
                  }}
                  align="center"
                >
                  № Удостоверения
                </TableCell>
                <TableCell
                  style={{
                    borderBottom: 0,
                  }}
                  align="center"
                >
                  Срок действия удостоверения
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData?.executors?.map((executorData) => {
                const executor = executors.find(
                  (executor) =>
                    executor?.executorId === executorData?.executorId
                );
                return (
                  <TableRow key={executorData?.executorId}>
                    <TableCell align="center" component="th" scope="row">
                      {executor.surname} {executor.name} {executor.patronymic}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {executor.position}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {executor.stamp}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {executor.badgeNumber}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {executor.badgeExpirationDate}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </MaterialTable>
        </TableContainer>
      )
    );
  };

  return (
    <>
      <div className={styles.tableWrapper}>
        <Table
          title="Бригады"
          actions={
            userRole === "admin"
              ? [
                  {
                    icon: "add",
                    tooltip: "Добавить бригаду",
                    isFreeAction: true,
                    onClick: () => setIsModalOpen(true),
                  },
                  {
                    icon: "edit",
                    tooltip: "Редактировать бригаду",
                    onClick: (event, rowData) => {
                      setModalData(rowData);
                      setIsModalOpen(true);
                    },
                  },
                ]
              : []
          }
          deleteAction={userRole === "admin" ? deleteTeam : null}
          renderRowChildren={renderRowChildren}
          columns={columns}
          data={teams}
          isLoading={isRequesting}
        />
      </div>
      <ModalWindow
        isOpen={isModalOpen}
        headerText={modalData ? "Редактировать бригаду" : "Создать бригаду"}
        setIsOpen={(state) => {
          setIsModalOpen(state);
          setModalData(null);
        }}
        wrapperStyles={{ width: 400 }}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(variables) => {
            const { id, teamId, ...dataToSend } = variables;
            modalData
              ? editTeam({ ...variables, teamId }, id)
              : addTeam({ ...dataToSend });
            setIsModalOpen(false);
            setModalData(null);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            setFieldValue,
            handleBlur,
          }) => (
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.row}>
                <Input
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  style={{ width: 180, height: 40, paddingLeft: 20 }}
                  value={values.name}
                  name="name"
                  placeholder="Наименование"
                  onBlur={handleBlur}
                />
                <Input
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  style={{ width: 180, height: 40, paddingLeft: 20 }}
                  value={values.stamp}
                  name="stamp"
                  placeholder="Штамп"
                  onBlur={handleBlur}
                />
              </div>
              <div className={styles.row}>
                <Select
                  name="masterId"
                  value={values.masterId}
                  width="360px"
                  placeholder="Мастер"
                  onChange={(e) => {
                    setFieldValue("masterId", e.value);
                  }}
                  options={formattedMasters}
                />
              </div>
              {values.executorIds?.map((item, index) => (
                <div className={styles.row} key={index}>
                  <Select
                    name="executorIds"
                    value={values.executorIds?.[index]}
                    placeholder="Рабочий"
                    width="260px"
                    onChange={(e) => {
                      setFieldValue(
                        "executorIds",
                        values.executorIds?.map((item, itemIndex) => {
                          if (index === itemIndex) {
                            return e.value;
                          } else return item;
                        })
                      );
                    }}
                    options={formattedExecutors}
                  />

                  <div
                    onClick={() => {
                      values.executorIds.push("");
                      setFieldValue("executorIds", values.executorIds);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: 80,
                      cursor: "pointer",
                    }}
                  >
                    <AddIcon />
                  </div>
                </div>
              ))}
              <div className={styles.row}>
                <Button
                  disabled={requiredKeys.some((key) => !values[key])}
                  type="submit"
                >
                  {modalData ? "Сохранить" : "Создать"}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </ModalWindow>
    </>
  );
};
