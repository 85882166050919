import { createReducer } from "reduxsauce";
import actions from "./actions";

const { Types } = actions;
const INITIAL_STATE = {
  isRequesting: false,
  tasks: [],
  archive: [],
  error: null,
};

const request = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};
const loadTasksSuccess = (state = INITIAL_STATE, { tasks }) => {
  return {
    ...state,
    isRequesting: false,
    tasks,
  };
};

const loadArchiveTasksSuccess = (state = INITIAL_STATE, { tasks }) => {
  return {
    ...state,
    isRequesting: false,
    archive: tasks,
  };
};

const editTaskSuccess = (state = INITIAL_STATE, { task }) => {
  return {
    ...state,
    isRequesting: false,
    tasks: state.tasks?.map((item) => (item.id === task.id ? task : item)),
  };
};

const deleteTaskSuccess = (state = INITIAL_STATE, { id }) => {
  return {
    ...state,
    isRequesting: false,
    tasks: state.tasks.filter((item) => item.id !== id),
  };
};

const downloadTasksSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: false,
  };
};

const archiveTasksSuccess = (state = INITIAL_STATE, { id }) => {
  return {
    ...state,
    isRequesting: false,
    tasks: state.tasks.filter((item) => item.id !== id),
  };
};

const activateTasksSuccess = (state = INITIAL_STATE, { id }) => {
  return {
    ...state,
    isRequesting: false,
    archive: state.archive.filter((item) => item.id !== id),
  };
};

const uploadTasksSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: false,
  };
};

const addTaskSuccess = (state = INITIAL_STATE, { task }) => {
  return {
    ...state,
    isRequesting: false,
    tasks: [...state.tasks, task],
  };
};

const failure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    error,
  };
};

export const HANDLERS = {
  [Types.LOAD_TASKS_REQUEST]: request,
  [Types.LOAD_TASKS_SUCCESS]: loadTasksSuccess,
  [Types.LOAD_TASKS_FAILURE]: failure,

  [Types.LOAD_ARCHIVE_TASKS_REQUEST]: request,
  [Types.LOAD_ARCHIVE_TASKS_SUCCESS]: loadArchiveTasksSuccess,
  [Types.LOAD_ARCHIVE_TASKS_FAILURE]: failure,

  [Types.EDIT_TASK_REQUEST]: request,
  [Types.EDIT_TASK_SUCCESS]: editTaskSuccess,
  [Types.EDIT_TASK_FAILURE]: failure,

  [Types.DELETE_TASK_REQUEST]: request,
  [Types.DELETE_TASK_SUCCESS]: deleteTaskSuccess,
  [Types.DELETE_TASK_FAILURE]: failure,

  [Types.DOWNLOAD_TASKS_REQUEST]: request,
  [Types.DOWNLOAD_TASKS_SUCCESS]: downloadTasksSuccess,
  [Types.DOWNLOAD_TASKS_FAILURE]: failure,

  [Types.ARCHIVE_TASKS_REQUEST]: request,
  [Types.ARCHIVE_TASKS_SUCCESS]: archiveTasksSuccess,
  [Types.ARCHIVE_TASKS_FAILURE]: failure,

  [Types.ACTIVATE_TASKS_REQUEST]: request,
  [Types.ACTIVATE_TASKS_SUCCESS]: activateTasksSuccess,
  [Types.ACTIVATE_TASKS_FAILURE]: failure,

  [Types.UPLOAD_TASKS_REQUEST]: request,
  [Types.UPLOAD_TASKS_SUCCESS]: uploadTasksSuccess,
  [Types.UPLOAD_TASKS_FAILURE]: failure,

  [Types.ADD_TASK_REQUEST]: request,
  [Types.ADD_TASK_SUCCESS]: addTaskSuccess,
  [Types.ADD_TASK_FAILURE]: failure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
