import { takeLatest, call, put } from "redux-saga/effects";
import api from "services/api";
import { saveAs } from "file-saver";
import Axios from "axios";
import tasksActions from "./actions";
import errorActions from "../error/actions";

const {
  Types: {
    LOAD_TASKS_REQUEST,
    LOAD_ARCHIVE_TASKS_REQUEST,
    ADD_TASK_REQUEST,
    EDIT_TASK_REQUEST,
    DELETE_TASK_REQUEST,
    DOWNLOAD_TASKS_REQUEST,
    ARCHIVE_TASKS_REQUEST,
    ACTIVATE_TASKS_REQUEST,
    UPLOAD_TASKS_REQUEST,
  },
  Creators: {
    loadTasksSuccess,
    loadTasksFailure,
    loadArchiveTasksSuccess,
    loadArchiveTasksFailure,
    addTaskSuccess,
    addTaskFailure,
    deleteTaskSuccess,
    deleteTaskFailure,
    editTaskSuccess,
    editTaskFailure,
    downloadTasksSuccess,
    downloadTasksFailure,
    archiveTasksSuccess,
    archiveTasksFailure,
    activateTasksSuccess,
    activateTasksFailure,
    uploadTasksSuccess,
    uploadTasksFailure,
  },
} = tasksActions;

const {
  Creators: { setError },
} = errorActions;

function* loadTasks() {
  try {
    const { data } = yield call(api.get, `/Task/Active`);
    yield put(loadTasksSuccess(data));
  } catch (error) {
    yield put(loadTasksFailure(error));
    yield put(setError(error?.response?.data?.[0]?.Message));
  }
}

function* loadArchiveTasks() {
  try {
    const { data } = yield call(api.get, `/Task/Archived`);
    yield put(loadArchiveTasksSuccess(data));
  } catch (error) {
    yield put(loadArchiveTasksFailure(error));
    yield put(setError(error?.response?.data?.[0]?.Message));
  }
}

function* addTask({ payload }) {
  try {
    const { data } = yield call(api.post, `/Task`, payload);
    yield put(addTaskSuccess(data));
  } catch (error) {
    yield put(addTaskFailure(error));
    yield put(setError(error?.response?.data?.[0]?.Message));
  }
}

function* editTask({ payload }) {
  try {
    const { data } = yield call(api.put, `/Task/${payload.id}`, payload);
    yield put(editTaskSuccess(data));
  } catch (error) {
    yield put(editTaskFailure(error));
    yield put(setError(error?.response?.data?.[0]?.Message));
  }
}

function* deleteTask({ payload }) {
  try {
    const { data } = yield call(api.remove, `/Task/${payload.id}`);
    yield put(deleteTaskSuccess(data));
  } catch (error) {
    yield put(deleteTaskFailure(error));
    yield put(setError(error?.response?.data?.[0]?.Message));
  }
}

function* downloadTasks({ payload }) {
  try {
    Axios({
      method: "post",
      url: `/api/files/tasks?masterId=${payload}`,
      responseType: "arraybuffer",
    }).then((response) => {
      const url = new Blob([response.data], { type: "application/zip" });
      saveAs(url, payload);
    });

    yield put(downloadTasksSuccess());
  } catch (error) {
    yield put(downloadTasksFailure(error));
    yield put(setError(error?.response?.data?.[0]?.Message));
  }
}

function* archiveTask({ payload }) {
  try {
    yield call(api.put, `/Task/${payload}/Archive`);
    yield put(archiveTasksSuccess(payload));
  } catch (error) {
    yield put(archiveTasksFailure(error));
    yield put(setError(error?.response?.data?.[0]?.Message));
  }
}

function* activateTask({ payload }) {
  try {
    yield call(api.put, `/Task/${payload}/Activate`);
    yield put(activateTasksSuccess(payload));
  } catch (error) {
    yield put(activateTasksFailure(error));
    yield put(setError(error?.response?.data?.[0]?.Message));
  }
}

function* uploadTasks({ payload }) {
  try {
    let data;

    const requestData = new FormData();
    payload.map((file) => {
      requestData.append("file", file);
      requestData.append("filename", file.name);
    });

    if (payload.length === 1) {
      data = yield call(api.post, `/api/files/load-file/`, requestData);
    } else {
      data = yield call(api.post, `/api/files/load-files/`, requestData);
    }
    yield put(uploadTasksSuccess(data));
  } catch (error) {
    yield put(uploadTasksFailure(error));
    yield put(setError(error?.response?.data?.[0]?.Message));
  }
}

export function* tasksSaga() {
  yield takeLatest(LOAD_TASKS_REQUEST, loadTasks);
  yield takeLatest(LOAD_ARCHIVE_TASKS_REQUEST, loadArchiveTasks);
  yield takeLatest(ADD_TASK_REQUEST, addTask);
  yield takeLatest(DELETE_TASK_REQUEST, deleteTask);
  yield takeLatest(EDIT_TASK_REQUEST, editTask);
  yield takeLatest(DOWNLOAD_TASKS_REQUEST, downloadTasks);
  yield takeLatest(ARCHIVE_TASKS_REQUEST, archiveTask);
  yield takeLatest(ACTIVATE_TASKS_REQUEST, activateTask);
  yield takeLatest(UPLOAD_TASKS_REQUEST, uploadTasks);
}
