import React, { useState } from "react";
import { Table } from "components/shared/Table";
import Input from "components/shared/Input";
import Button from "components/shared/Button";
import Select from "components/shared/Select";
import { ResultsModal } from "components/shared/ResultsModal";
import styles from "../styles.module.css";
import ModalWindow from "components/shared/ModalWindow";
import SaveIcon from "@material-ui/icons/Save";
import { Formik } from "formik";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  rowStyle: {
    padding: 10,
    border: "1px solid red",
  },
}));

const levels = [
  { value: "master", label: "Мастер" },
  { value: "executor", label: "Сварщик" },
  { value: "controller", label: "Tex. надзор" },
];

const dateOptions = {
  day: "numeric",
  month: "short",
  year: "numeric",
};

export const ExecutorsTable = ({
  addExecutor,
  deleteExecutor,
  editExecutor,
  executors,
  teams,
  isRequesting,
  type,
  userRole,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [isResultsModalOpen, setIsResultsModalOpen] = useState(false);
  const [accessLevel, setAccessLevel] = useState(type);
  const [activeExecutor, setActiveExecutor] = useState("");

  const classes = useStyles();

  const handleLevelChange = (select) => {
    setAccessLevel(select.value);
  };

  const initialValues = {
    userId:
      type === "executor"
        ? modalData?.executorId
        : type === "master"
        ? modalData?.masterId
        : modalData?.techUserId ?? "",
    name: modalData?.name ?? "",
    email: modalData?.email ?? "",
    role: modalData?.role ?? type,
    surname: modalData?.surname ?? "",
    patronymic: modalData?.patronymic ?? "",
    badgeNumber: modalData?.badgeNumber ?? "",
    position: modalData?.position ?? "",
    badgeExpirationDate: modalData?.badgeExpirationDate ?? "",
    stamp: modalData?.stamp ?? "",
    photo: modalData?.photo ?? "",
    qualificationArea: modalData?.qualificationArea ?? "",
  };

  const requiredKeys = [
    "userId",
    "name",
    "badgeNumber",
    "badgeExpirationDate",
    "surname",
    "position",
    "email",
    "role",
    "patronymic",
  ];

  const executorColumns = [
    {
      title: "Фото",
      field: "photo",
      render: (rowData) => (
        <img
          style={{
            width: 40,
            height: 40,
            borderRadius: "50%",
          }}
          src={
            rowData.photo ??
            "https://www.pngfind.com/pngs/b/110-1102775_download-empty-profile-hd-png-download.png"
          }
          alt=""
        />
      ),
    },
    {
      title: "ФИО",
      field: "name",
      render: (rowData) => (
        <span>{`${rowData.surname} ${rowData.name} ${rowData.patronymic}`}</span>
      ),
    },
    {
      title: "RFID метка",
      field: "executorId",
    },
    {
      title: "Должность",
      field: "position",
    },
    {
      title: "№ Удостоверения",
      field: "badgeNumber",
    },
    {
      title: "Срок действия удостоверения",
      field: "badgeExpirationDate",
      render: (rowData) => (
        <span>
          {new Date(rowData?.badgeExpirationDate).toLocaleDateString(
            "ru-RU",
            dateOptions
          )}
        </span>
      ),
    },
    {
      title: "Область распространения квалификации",
      field: "qualificationArea",
      width: 400,
    },
    { title: "Клеймо", field: "stamp" },
    {
      title: "Бригада",
      field: "teamId",
      render: (rowData) => {
        const team = teams.find((item) => item.teamId === rowData.teamId);
        return <p>{team?.name}</p>;
      },
    },
    {
      field: "link",
      title: "Отчет",
      render: (rowData) => (
        <div
          onClick={() => {
            setIsResultsModalOpen(true);
            setActiveExecutor(rowData?.executorId);
          }}
        >
          <SaveIcon />
        </div>
      ),
      width: 54,
    },
  ];

  const extraUserColumns = [
    {
      title: "Фото",
      field: "photo",
      render: (rowData) => (
        <img
          style={{ width: 40, height: 40, borderRadius: "50%" }}
          src={
            rowData.photo ??
            "https://www.pngfind.com/pngs/b/110-1102775_download-empty-profile-hd-png-download.png"
          }
          alt=""
        />
      ),
    },
    {
      title: "ФИО",
      field: "name",
      render: (rowData) => (
        <span>{`${rowData.surname} ${rowData.name} ${rowData.patronymic}`}</span>
      ),
    },
    {
      title: "RFID метка",
      field: "masterId",
      render: (rowData) => (
        <span>{type === "master" ? rowData.masterId : "---"}</span>
      ),
    },
    {
      title: "Должность",
      field: "position",
    },
    {
      title: "№ Удостоверения",
      field: "badgeNumber",
    },
    {
      title: "Срок действия удостоверения",
      field: "badgeExpirationDate",
      render: (rowData) => (
        <span>
          {new Date(rowData?.badgeExpirationDate).toLocaleDateString(
            "ru-RU",
            dateOptions
          )}
        </span>
      ),
    },
  ];

  return (
    <>
      <div className={styles.tableWrapper}>
        <Table
          title="Сотрудники"
          isLoading={isRequesting}
          actions={
            userRole === "admin"
              ? [
                  {
                    icon: "add",
                    tooltip: "Добавить пользователя",
                    isFreeAction: true,
                    onClick: () => setIsModalOpen(true),
                  },
                  {
                    icon: "edit",
                    tooltip: "Редактировать пользователя",
                    onClick: (event, rowData) => {
                      setModalData(rowData);
                      setIsModalOpen(true);
                    },
                  },
                ]
              : []
          }
          deleteAction={userRole === "admin" ? deleteExecutor : null}
          rowStyle={classes.rowStyle}
          columns={type === "executor" ? executorColumns : extraUserColumns}
          data={executors}
        />
      </div>
      <ResultsModal
        type={"EXECUTOR"}
        activeId={activeExecutor}
        isOpen={isResultsModalOpen}
        setIsOpen={setIsResultsModalOpen}
      />
      <ModalWindow
        isOpen={isModalOpen}
        headerText={
          modalData ? "Редактировать пользователя" : "Создать пользователя"
        }
        setIsOpen={(state) => {
          setIsModalOpen(state);
          setModalData(null);
        }}
        wrapperStyles={{ width: 600 }}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(variables) => {
            const { id, ...dataToSend } = variables;
            modalData
              ? editExecutor(
                  { ...variables },
                  accessLevel === "executor"
                    ? modalData.executorId
                    : accessLevel === "master"
                    ? modalData.masterId
                    : modalData.techUserId
                )
              : addExecutor({ ...dataToSend });
            setIsModalOpen(false);
            setModalData(null);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            isValid,
            setFieldValue,
            dirty,
            handleBlur,
          }) => (
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.row}>
                <Input
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  width="200"
                  style={{ height: 40, padding: "0 20px 0 30px" }}
                  value={values.surname}
                  name="surname"
                  placeholder="Фамилия"
                  onBlur={handleBlur}
                />
                <Input
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  width="200"
                  style={{ height: 40, padding: "0 20px 0 30px" }}
                  value={values.name}
                  name="name"
                  placeholder="Имя"
                  onBlur={handleBlur}
                />
                <Input
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  style={{ height: 40, padding: "0 20px 0 30px" }}
                  value={values.patronymic}
                  name="patronymic"
                  placeholder="Отчество"
                  onBlur={handleBlur}
                />
              </div>
              <div className={styles.row}>
                <Input
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  width="186px"
                  style={{ height: 40, width: 186 }}
                  value={values.email}
                  name="email"
                  placeholder="email"
                  onBlur={handleBlur}
                  type="user"
                  autoComplete="email"
                />
                <Input
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  style={{ height: 40, width: 186, padding: "0 20px 0 30px" }}
                  value={values.photo}
                  name="photo"
                  placeholder="Ссылка на фото"
                  onBlur={handleBlur}
                  autoComplete="photo"
                />
                <Select
                  name="role"
                  value={values.role}
                  width="186px"
                  style={{ height: 40, width: 186 }}
                  onChange={(e) => {
                    handleLevelChange(e);
                    setFieldValue("role", e.value);
                  }}
                  options={[levels.find((item) => item.value === type)]}
                />
              </div>
              <div className={styles.row}>
                <Input
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  style={{ height: 40, padding: "0 20px 0 30px" }}
                  value={values.position}
                  name="position"
                  placeholder="Должность"
                  onBlur={handleBlur}
                />
                <Input
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  style={{ height: 40, padding: "0 20px 0 30px" }}
                  value={values.badgeNumber}
                  name="badgeNumber"
                  placeholder="Номер удостоверения"
                  onBlur={handleBlur}
                />
                <Input
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  style={{ height: 40, padding: "0 20px 0 30px" }}
                  value={values.badgeExpirationDate}
                  name="badgeExpirationDate"
                  type="text"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                  }}
                  placeholder="Срок действия"
                  onBlur={handleBlur}
                />
              </div>
              {accessLevel === "executor" && type === "executor" && (
                <div className={styles.row}>
                  <Input
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    style={{ width: 260, height: 40, padding: "0 20px 0 30px" }}
                    value={values.stamp}
                    name="stamp"
                    placeholder="Клеймо"
                    onBlur={handleBlur}
                  />
                  <Input
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    style={{ width: 260, height: 40, padding: "0 20px 0 30px" }}
                    value={values.qualificationArea}
                    name="qualificationArea"
                    placeholder="Область распростр. квалификации"
                    onBlur={handleBlur}
                  />
                </div>
              )}
              {!modalData && (
                <div className={styles.row}>
                  <Input
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    style={{ height: 40, padding: "0 20px 0 30px" }}
                    value={values.userId}
                    name="userId"
                    placeholder="RFID метка"
                    onBlur={handleBlur}
                  />
                </div>
              )}
              <div className={styles.row}>
                <Button
                  disabled={requiredKeys.some((key) => !values[key])}
                  type="submit"
                >
                  {modalData ? "Сохранить" : "Создать"}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </ModalWindow>
    </>
  );
};
