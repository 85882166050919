import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  loadTeamsRequest: ["payload"],
  loadTeamsSuccess: ["teams"],
  loadTeamsFailure: ["error"],

  editTeamRequest: ["payload", "id"],
  editTeamSuccess: ["team", "id"],
  editTeamFailure: ["error"],

  addTeamRequest: ["payload"],
  addTeamSuccess: ["team"],
  addTeamFailure: ["error"],

  deleteTeamRequest: ["payload"],
  deleteTeamSuccess: ["id"],
  deleteTeamFailure: ["error"],
});

const teamsActions = {
  Types,
  Creators,
};

export default teamsActions;
