import React from "react";
import { HeaderLayout } from "layouts/Header";
import { Archive } from "components/Archive";

export const ArchivePage = (props) => {
  return (
    <div>
      <HeaderLayout>
        <Archive {...props} />
      </HeaderLayout>
    </div>
  );
};
