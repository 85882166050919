import { takeLatest, call, put } from "redux-saga/effects";
import api from "services/api";
import teamsActions from "./actions";
import errorActions from "../error/actions";

const {
  Types: {
    LOAD_TEAMS_REQUEST,
    EDIT_TEAM_REQUEST,
    DELETE_TEAM_REQUEST,
    ADD_TEAM_REQUEST,
  },
  Creators: {
    loadTeamsSuccess,
    loadTeamsFailure,
    editTeamSuccess,
    editTeamFailure,
    addTeamSuccess,
    addTeamFailure,
    deleteTeamSuccess,
    deleteTeamFailure,
  },
} = teamsActions;

const {
  Creators: { setError },
} = errorActions;

function* loadTeams() {
  try {
    const { data } = yield call(api.get, `/Team`);
    yield put(loadTeamsSuccess(data));
  } catch (error) {
    yield put(loadTeamsFailure(error));
    yield put(setError(error?.response?.data?.[0]?.Message));
  }
}

function* addTeam({ payload }) {
  try {
    const { data } = yield call(api.post, `/Team`, payload);
    yield put(addTeamSuccess(data));
  } catch (error) {
    yield put(addTeamFailure(error));
    yield put(setError(error?.response?.data?.[0]?.Message));
  }
}

function* editTeam({ payload, id }) {
  try {
    const { data } = yield call(api.put, `/Team/${id}`, payload);
    yield put(editTeamSuccess(data, id));
  } catch (error) {
    yield put(editTeamFailure(error));
    yield put(setError(error?.response?.data?.[0]?.Message));
  }
}

function* deleteTeam({ payload }) {
  try {
    const { data } = yield call(api.remove, `/Team/${payload.id}`);
    yield put(deleteTeamSuccess(data));
  } catch (error) {
    yield put(deleteTeamFailure(error));
    yield put(setError(error?.response?.data?.[0]?.Message));
  }
}

export function* teamsSaga() {
  yield takeLatest(LOAD_TEAMS_REQUEST, loadTeams);
  yield takeLatest(ADD_TEAM_REQUEST, addTeam);
  yield takeLatest(DELETE_TEAM_REQUEST, deleteTeam);
  yield takeLatest(EDIT_TEAM_REQUEST, editTeam);
}
