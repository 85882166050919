import SaveIcon from "@material-ui/icons/ArrowDownward";
import Inventory from "@material-ui/icons/Archive";
import { tasksImage } from "assets/pics";
import Button from "components/shared/Button";
import Input from "components/shared/Input";
import ModalWindow from "components/shared/ModalWindow";
import Select from "components/shared/Select";
import { Table } from "components/shared/Table";
import ToolTip from "components/shared/ToolTip";
import { Formik } from "formik";
import React, { useEffect, useState, useCallback } from "react";
import api from "services/api";
import styles from "./styles.module.css";
import errorActions from "store/error/actions";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import moment from "moment";

const {
  Creators: { setError },
} = errorActions;

export const Tasks = ({
  loadTasks,
  tasks,
  addTask,
  deleteTask,
  editTask,
  loadTechs,
  loadTeams,
  loadMasters,
  loadInstructions,
  downloadTasks,
  archiveTasks,
  uploadTasks,
  techs,
  teams,
  masters,
  isRequesting,
  instructions,
  userRole,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [masterId, setMasterId] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [myFiles, setMyFiles] = useState([]);
  const dispatch = useDispatch();

  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    [myFiles]
  );
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const removeFile = (file) => () => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const removeAll = () => {
    setMyFiles([]);
  };

  const files = myFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes{" "}
      <button onClick={removeFile(file)}>X</button>
    </li>
  ));

  const initialValues = {
    id: modalData?.id ?? "",
    taskId: modalData?.taskId ?? "",
    object: modalData?.object ?? "",
    sector: modalData?.sector ?? "",
    weldingConnectionName: modalData?.weldingConnectionName ?? "",
    instructionId: modalData?.instruction.id ?? "",
    teamId: modalData?.team?.teamId ?? "",
    technicalControllerId: modalData?.technicalController?.techUserId ?? "",
    generalMaterial: modalData?.generalMaterial ?? "",
    weldingElectrodes: modalData?.weldingElectrodes ?? "",
    weldingWire: modalData?.weldingWire ?? "",
  };

  const requiredKeys = [
    "object",
    "sector",
    "weldingConnectionName",
    "teamId",
    "technicalControllerId",
    "weldingElectrodes",
    "generalMaterial",
    "weldingWire",
    "instructionId",
  ];

  useEffect(() => {
    loadTasks();
    loadTeams();
    loadTechs();
    loadInstructions();
    loadMasters();
  }, [loadInstructions, loadMasters, loadTasks, loadTeams, loadTechs]);

  const formattedTeams = teams?.map((item) => {
    return {
      value: item?.teamId,
      label: item?.name,
    };
  });

  const formattedTechs = techs?.map((item) => {
    return {
      value: item?.techUserId,
      label: `${item?.surname} ${item?.name}`,
    };
  });

  const formattedMasters = masters?.map((item) => {
    return {
      value: item.masterId,
      label: `${item.surname} ${item.name}`,
    };
  });

  const formattedInstructions = instructions?.map((item) => {
    return {
      value: item?.id,
      label: item?.otkName,
    };
  });

  const generateReport = (values) => {
    api
      .get(`/summaryReport?startAt=${values.startDate}&endAt=${values.endDate}`)
      .then((response) => {
        const wnd = window.open("about:blank", "", "_blank");
        wnd.document.write(response?.data);
      })
      .catch((error) => dispatch(setError(error.response.data)));
  };

  const getDocument = (activeId) => {
    api
      .get(`/passport/${activeId}`)
      .then((response) => {
        const wnd = window.open("about:blank", "", "_blank");
        wnd.document.write(response?.data);
      })
      .catch((error) => dispatch(setError(error?.response?.data?.title ?? "")));
  };

  const getUnfinishedTasks = (masterId) => {
    downloadTasks(masterId);
  };

  const callUploadTasks = (content) => {
    uploadTasks(content);
    setIsUploadModalOpen(false);
    setMyFiles([]);
  };

  const columns = [
    { title: "Номер Задания", field: "id" },
    {
      title: "Дата Выполнения",
      field: "finishedAt",
      render: (rowData) => {
        return (
          <p>
            {rowData?.finishedAt
              ? moment(rowData.finishedAt).format("DD.MM.YYYY")
              : "--.--.----"}
          </p>
        );
      },
    },
    {
      title: "Наименование (маркировка сварного соединения)",
      field: "weldingConnectionName",
    },
    { title: "Объект", field: "object" },
    { title: "Участок/цех", field: "sector" },
    {
      title: "Мастер",
      field: "masterId",
      render: (rowData) => {
        const userData = rowData?.team?.master?.userData;
        return (
          <p>
            {userData?.surname} {userData?.name} {userData?.patronymic}
          </p>
        );
      },
    },
    {
      title: "Бригада",
      field: "teamId",
      render: (rowData) => {
        return <p>{rowData?.team?.name}</p>;
      },
    },
    {
      field: "url",
      title: "Скачать",
      render: (rowData) => (
        <div
          onClick={() => getDocument(rowData?.id)}
          className={styles.downloadButton}
        >
          <SaveIcon />
        </div>
      ),
      width: 54,
    },
    {
      field: "url",
      title: "В архив",
      render: (rowData) => (
        <div
          onClick={() => archiveTasks(rowData?.id)}
          className={styles.downloadButton}
        >
          <Inventory />
        </div>
      ),
      width: 54,
    },
  ];

  const renderRowChildren = (rowData) => {
    return (
      <div className={styles.column}>
        <p className={styles.bold}>Общая информация</p>
        <p>Номер задания: {rowData?.taskId}</p>
        <p>
          Операционная технологическая карта:{" "}
          <a
            // className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
            href={rowData?.instruction?.otkUrl}
          >
            {rowData?.instruction?.otkName ?? "---"}
          </a>
        </p>
        <p>
          Технический надзор:{" "}
          {rowData?.technicalController?.userData?.surname ?? "---"}
        </p>
        <div>
          <p className={styles.bold}>Исполнители</p>
          {rowData?.team?.executors?.map((item) => {
            return (
              <div>
                <div style={{ display: "block" }}>
                  <p>
                    {item?.userData?.surname} {item?.userData?.name}{" "}
                    {item?.userData?.patronymic}
                    {"\n"}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <div>
          <p className={styles.bold}>Оборудование</p>
          {rowData?.taskDataBridge?.map((item) => (
            <p>
              {item?.MachineId?.name} - {item?.MachineId?.serialNum}
            </p>
          ))}
        </div>

        <div>
          <p className={styles.bold}>Материалы</p>
          <div>
            <p>
              Основной материал/номер партии:{" "}
              {rowData?.generalMaterial ?? "---"}
            </p>
            <p>
              Сварочные электроды/номер партии:{" "}
              {rowData?.weldingElectrodes ?? "---"}
            </p>
            <p>
              Сварочная проволока/номер партии: {rowData?.weldingWire ?? "---"}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.innerWrapper}>
      <ToolTip
        title="Задания"
        toolTipText="Здесь Вы можете сформировать задания на сварку, просмотреть их выполнение, скачать паспорт сварного стыка"
        src={tasksImage}
        button={
          <div>
            <button
              type="button"
              className={styles.button}
              onClick={() => setIsDownloadModalOpen(true)}
            >
              Выгрузить задания
            </button>
            <button
              type="button"
              className={styles.button}
              onClick={() => setIsUploadModalOpen(true)}
            >
              Загрузить задания
            </button>
            <button
              type="button"
              className={styles.button}
              onClick={() => setIsReportModalOpen(true)}
            >
              Сводный отчет
            </button>
          </div>
        }
      />

      <ModalWindow
        isOpen={isReportModalOpen}
        headerText={"Выберите даты"}
        setIsOpen={(state) => {
          setIsDownloadModalOpen(state);
          setMasterId(null);
        }}
        wrapperStyles={{ width: 420 }}
      >
        <Formik
          initialValues={{ startDate: "", endDate: "" }}
          enableReinitialize
          onSubmit={(variables) => {
            generateReport(variables);
            setIsReportModalOpen(false);
          }}
        >
          {({ handleSubmit, handleChange, values, handleBlur }) => (
            <form className={styles.form} onSubmit={handleSubmit}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ marginBottom: 10 }} className={styles.row}>
                  <Input
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    style={{
                      height: 40,
                      width: 160,
                      padding: "0 20px 0 30px",
                      marginRight: 20,
                    }}
                    value={values.startDate}
                    name="startDate"
                    type="text"
                    onFocus={(e) => {
                      e.currentTarget.type = "date";
                    }}
                    placeholder="Начальная дата"
                    onBlur={handleBlur}
                  />
                  <Input
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    style={{ height: 40, width: 160, padding: "0 20px 0 30px" }}
                    value={values.endDate}
                    name="endDate"
                    type="text"
                    onFocus={(e) => {
                      e.currentTarget.type = "date";
                    }}
                    placeholder="Конечная дата"
                    onBlur={handleBlur}
                  />
                </div>
                <div className={styles.row}>
                  <Button type="submit">Открыть</Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </ModalWindow>
      <ModalWindow
        isOpen={isDownloadModalOpen}
        headerText={"Выберите мастера"}
        setIsOpen={(state) => {
          setIsDownloadModalOpen(state);
          setMasterId(null);
        }}
        wrapperStyles={{ width: 420 }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ marginBottom: 10 }} className={styles.row}>
            <Select
              value={masterId}
              placeholder="Мастер"
              onChange={(e) => {
                setMasterId(e.value);
              }}
              options={formattedMasters}
            />
          </div>
          <div className={styles.row}>
            <Button
              onClick={() => getUnfinishedTasks(masterId)}
              disabled={!masterId}
              type="submit"
            >
              Скачать
            </Button>
          </div>
        </div>
      </ModalWindow>
      <ModalWindow
        isOpen={isUploadModalOpen}
        headerText={"Загрузите задания"}
        setIsOpen={(state) => {
          setIsUploadModalOpen(state);
        }}
        wrapperStyles={{ width: 420 }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className={styles.row}>
            <div className="container">
              <div
                {...getRootProps({ className: "dropzone" })}
                className={styles.baseStyle}
              >
                <input {...getInputProps()} />
                <p>Перетяните или нажмите для загрузки</p>
              </div>
              <div>
                <h4>Файлы:</h4>
                <ul>{files}</ul>
              </div>
            </div>
          </div>
          <div className={styles.row}>
            <Button
              onClick={() => callUploadTasks(myFiles)}
              disabled={!myFiles.length}
              type="submit"
            >
              Загрузить
            </Button>
          </div>
        </div>
      </ModalWindow>
      <div className={styles.tableWrapper}>
        <Table
          title="Задания"
          columns={columns}
          data={tasks}
          isLoading={isRequesting}
          renderRowChildren={renderRowChildren}
          deleteAction={
            userRole === "admin" || userRole === "master" ? deleteTask : null
          }
          actions={
            userRole === "admin" || userRole === "master"
              ? [
                  {
                    icon: "add",
                    tooltip: "Создать задачу",
                    isFreeAction: true,
                    onClick: () => setIsModalOpen(true),
                  },
                  {
                    icon: "edit",
                    tooltip: "Редактировать задачу",
                    onClick: (event, rowData) => {
                      setModalData(rowData);
                      setIsModalOpen(true);
                    },
                  },
                ]
              : []
          }
        />
        <ModalWindow
          isOpen={isModalOpen}
          headerText={modalData ? "Редактировать задание" : "Добавить задание"}
          setIsOpen={(state) => {
            setIsModalOpen(state);
            setModalData(null);
          }}
          wrapperStyles={{ width: 420 }}
        >
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(variables) => {
              const { id, ...dataToSend } = variables;
              modalData
                ? editTask({ ...variables })
                : addTask({ ...dataToSend });
              setIsModalOpen(false);
              setModalData(null);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              setFieldValue,
              handleBlur,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className={styles.row}>
                  <Input
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    style={{ height: 40, padding: "0 20px 0 30px" }}
                    value={values.object}
                    name="object"
                    placeholder="Объект"
                    onBlur={handleBlur}
                  />
                  <Input
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    style={{ height: 40, padding: "0 20px 0 30px" }}
                    value={values.sector}
                    name="sector"
                    placeholder="Участок/цех"
                    onBlur={handleBlur}
                  />
                </div>
                <div className={styles.row}>
                  <Input
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    style={{ height: 40, padding: "0 20px 0 30px", width: 380 }}
                    value={values.weldingConnectionName}
                    name="weldingConnectionName"
                    placeholder="Наименовние соединения"
                    onBlur={handleBlur}
                  />
                </div>
                <div className={styles.row}>
                  <Select
                    name="instructionId"
                    value={values.instructionId}
                    style={{ height: 40, padding: "0 20px 0 30px" }}
                    width="190px"
                    placeholder="Инструкция"
                    onChange={(e) => {
                      setFieldValue("instructionId", e.value);
                    }}
                    options={formattedInstructions}
                  />
                  <Select
                    name="teamId"
                    value={values.teamId}
                    style={{ height: 40, padding: "0 20px 0 30px" }}
                    width="190px"
                    placeholder="Бригада"
                    onChange={(e) => {
                      setFieldValue("teamId", e.value);
                    }}
                    options={formattedTeams}
                  />
                </div>

                <div className={styles.row}>
                  <Input
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    style={{ height: 40, padding: "0 20px 0 30px" }}
                    value={values.generalMaterial}
                    name="generalMaterial"
                    placeholder="Материал"
                    onBlur={handleBlur}
                  />
                  <Select
                    name="technicalControllerId"
                    value={values.technicalControllerId}
                    style={{ height: 40, padding: "0 20px 0 30px" }}
                    width="190px"
                    placeholder="Тех. надзор"
                    onChange={(e) => {
                      setFieldValue("technicalControllerId", e.value);
                    }}
                    options={formattedTechs}
                  />
                </div>
                <div className={styles.row}>
                  <Input
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    style={{ height: 40, padding: "0 20px 0 30px" }}
                    value={values.weldingElectrodes}
                    name="weldingElectrodes"
                    placeholder="Электроды"
                    onBlur={handleBlur}
                  />
                  <Input
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    style={{ height: 40, padding: "0 20px 0 30px" }}
                    value={values.weldingWire}
                    name="weldingWire"
                    placeholder="Проволока"
                    onBlur={handleBlur}
                  />
                </div>
                <div className={styles.row}>
                  <Button
                    disabled={requiredKeys.some((key) => !values[key])}
                    type="submit"
                  >
                    {modalData ? "Сохранить" : "Создать"}
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </ModalWindow>
      </div>
    </div>
  );
};
