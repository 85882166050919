import { createReducer } from "reduxsauce";
import actions from "./actions";

const { Types } = actions;
const INITIAL_STATE = {
  isRequesting: false,
  teams: [],
  error: null,
};

const request = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};
const loadTeamsSuccess = (state = INITIAL_STATE, { teams }) => {
  return {
    ...state,
    isRequesting: false,
    teams,
  };
};

const editTeamSuccess = (state = INITIAL_STATE, { team, id }) => {
  return {
    ...state,
    isRequesting: false,
    teams: state.teams?.map((item) =>
      item.id === id ? { ...item, ...team } : item
    ),
  };
};

const deleteTeamSuccess = (state = INITIAL_STATE, { id }) => {
  return {
    ...state,
    isRequesting: false,
    teams: state.teams?.filter((item) => item.id !== id),
  };
};

const addTeamSuccess = (state = INITIAL_STATE, { team }) => {
  return {
    ...state,
    isRequesting: false,
    teams: [...state.teams, team],
  };
};

const failure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    error,
  };
};

export const HANDLERS = {
  [Types.LOAD_TEAMS_REQUEST]: request,
  [Types.LOAD_TEAMS_SUCCESS]: loadTeamsSuccess,
  [Types.LOAD_TEAMS_FAILURE]: failure,

  [Types.EDIT_TEAM_REQUEST]: request,
  [Types.EDIT_TEAM_SUCCESS]: editTeamSuccess,
  [Types.EDIT_TEAM_FAILURE]: failure,

  [Types.DELETE_TEAM_REQUEST]: request,
  [Types.DELETE_TEAM_SUCCESS]: deleteTeamSuccess,
  [Types.DELETE_TEAM_FAILURE]: failure,

  [Types.ADD_TEAM_REQUEST]: request,
  [Types.ADD_TEAM_SUCCESS]: addTeamSuccess,
  [Types.ADD_TEAM_FAILURE]: failure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
